import React from 'react'
import GamerArea from '../headertwo/GamerArea'
import Gallery from './Gallery'
import Tournament from './Tournament'
import PlayerArea from '../headertwo/PlayerArea'

function AreaBg() {
  return (
        <>
            <GamerArea/>
            <Tournament/>
            <Gallery/>
            {/* <Live/>
            <Newsletter/> */}
            <PlayerArea/>
        </>
  )
}

export default AreaBg