import React from 'react'

function PlayerArea() {
  return (
    <section className="give-player-area give-player-bg pt-110">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-title title-style-two text-center mb-70">
            <h2 className="title">App Highlights</h2>
          </div>
        </div>
      </div>
      <div className="row align-items-center justify-content-center justify-content-md-between justify-content-lg-around">
        <div className="col-xl-4 col-lg-5 col-md-6">
          <div className="gp-item mb-70 wow fadeInUp" data-wow-delay=".2s">
            <div className="gp-item-top flex-md-row-reverse">
              <img src="assets/img/icon/gp_icon01.png" alt="" />
              <h4 className="title">Cruises</h4>
            </div>
            <div className="gp-content text-end">
              <p>Think of Cruises like Sprints in racing games, but without the racing. By completing Cruises successfully, you will earn rewards and leaderboard placement, unlock new Cruises, level through the ranks, and unlock new avatars.</p>
            </div>
          </div>
          <div className="gp-item mb-70 wow fadeInUp" data-wow-delay=".4s">
            <div className="gp-item-top flex-md-row-reverse">
              <img src="assets/img/icon/gp_icon02.png" alt="" />
              <h4 className="title">Custom Routes</h4>
            </div>
            <div className="gp-content text-end">
              <p>Our events and Cruises are preset with custom routes so you will always enjoy the best roads without being rerouted to the quickest route.</p>
            </div>
          </div>
          <div className="gp-item mb-70 wow fadeInUp" data-wow-delay=".6s">
            <div className="gp-item-top flex-md-row-reverse">
              <img src="assets/img/icon/gp_icon03.png" alt="" />
              <h4 className="title">Map &amp; GPS</h4>
            </div>
            <div className="gp-content text-end">
              <p>Our interactive map and gps will get you to your destination in style!</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 d-none d-xl-block">
          <div className="gp-img">
            <img src="assets/img/images/give_player.png" alt="" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-5 col-md-6 split-section">
          <div className="gp-item right mb-70 wow fadeInUp" data-wow-delay=".2s">
            <div className="gp-item-top">
              <img src="assets/img/icon/gp_icon04.png" alt="" />
              <h4 className="title">Real-time Convoying </h4>
            </div>
            <div className="gp-content">
              <p>See other fellow enthusiasts on the map in real-time and never worry about losing your group again!</p>
            </div>
          </div>
          <div className="gp-item right mb-70 wow fadeInUp" data-wow-delay=".4s">
            <div className="gp-item-top">
              <img src="assets/img/icon/gp_icon05.png" alt="" />
              <h4 className="title">Event Creation</h4>
            </div>
            <div className="gp-content">
              <p>Create your own interactive Events and Cruises within the app. With several customizable options, the types of events you and your group can host are endless.</p>
            </div>
          </div>
          <div className="gp-item right mb-70 wow fadeInUp" data-wow-delay=".6s">
            <div className="gp-item-top">
              <img src="assets/img/icon/gp_icon06.png" alt="" />
              <h4 className="title">Avatars</h4>
            </div>
            <div className="gp-content">
              <p>Choose from a variety of avatars which represent you on the map and can be customized to your liking.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default PlayerArea