import React from 'react'
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import YoutubeEmbed from "./YoutubeEmbed";
function GamerArea() {
  return (
    <section className="just-gamer-area pt-120 pb-105">
          <div className="just-gamer-shape" />
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-6 col-lg-8 order-2 order-xl-0">
                <div className="just-gamer-content-box">
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="home" title="real world">
                        <div className="section-title">
                        <h2 className="title">DRIVE IN THE REAL <span>WORLD</span></h2>
                        <p>Welcome to the most engaging automotive experience ever! Using our interactive mobile app, complete unique and scenic Cruises, 
                           road rallies and checkpoint challenges to earn rewards and prizes, and work your way up the leaderboard.</p>
                      </div>
                      <div className="gamer-content">
                        <i className="far fa-arrow-alt-circle-right" />
                        <a href="https://onelink.to/k8u4g4"><p>Download now!</p></a>
                      </div>
                        </Tab>
                        
                        <Tab eventKey="homprofilee" title="immersive app">
                        <div className="section-title">
                        <h2 className="title">BECOME A LEGEND IN <span>ANOTHER</span></h2>
                        <p>Our unique gps based mobile app focuses on making your travel more engaging than ever. Gone are the days of looking for reasons to drive, welcome the era of Midnight Noise!</p>
                      </div>
                      <div className="gamer-content">
                        <i className="far fa-arrow-alt-circle-right" />
                        <a href="https://onelink.to/k8u4g4"><p>Download now!</p></a>
                      </div>
                        </Tab>
                        
                       
              </Tabs>
                  
                </div>
              </div>
              <div className="col-xl-6 col-lg-8">
                <div className="just-gamer-img position-relative text-center text-xxl-end">
                <div className="main-img">
                {/*<ReactPlayer
                url={props.https://www.youtube.com/watch?v=-sQJ7w0bXxM}
                playing={true}
                width={props.width}
                height={props.height}
                />*/}                
                <YoutubeEmbed embedId="gbxYYJVUPmE" />
                </div>
                  {/*<img src="assets/img/images/game_img01.png" alt="" className="main-img" />*/}                  
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default GamerArea