import React from 'react'
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

function Details() {
  return (
    <section className="blog-details-area black-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-item mb-60">
                <div className="blog-content blog-details-content">
                  <section className="just-gamer-area pt-120 pb-105">
              <div className="row align-items-center justify-content-center">
                <div className="just-gamer-content-box">
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="home" title="privacy policy">
                        <p>Last revised: January 14, 2019</p>

                        <p>Midnight Noise is a mobile app developed by Midnight Noise Inc. (the “Company”, “we” or “us”), that is made available to you via the Midnight Noise mobile app (the “App”), for which we provide further information on at: https://www.midnightnoise.com/ (the “Site”, collectively, with the App, the “Service”). We are  committed to protecting your privacy and safeguarding your Personal  Information (as defined in question 2). The purpose of this Privacy  Statement is to inform you about the types of Personal Information the  Company collects, uses and discloses. It explains how we use and  disclose that information, the choices you have regarding such use and  disclosure, and how you may correct that information.
                        Your privacy is one of our priorities. We are proud to demonstrate  our commitment to your privacy by complying with the laws and  regulations under applicable privacy laws, including the General Data  Protection Regulation (“GDPR”) (EU) 2016/679. Specifically, as we  are a Canadian organization, this Privacy Statement is designed to meet  the standards prescribed by the Personal Information Protection and  Electronic Documents Act and the regulations thereunder (“PIPEDA”)  as well as applicable provincial privacy legislation and regulations,  including, the Personal Information Protection Act (Alberta), the  Personal Information Protection Act (British Columbia), and An Act  respecting the protection of personal information in the private sector  (Quebec) (collectively “Canadian Privacy Laws”).
                        From time to time, we may make changes to this Privacy Statement. The  Privacy Statement is current as of the “last revised” date which  appears at the top of this page. We will treat Personal Information in a  manner consistent with the Privacy Statement under which it was  collected, unless we have your consent to treat it differently. This  Privacy Statement applies to any information we collect or receive about  you, from any source.
                        The Service may contain features or links to websites and services  provided by third parties. Any information you provide on third-party  sites or services is provided directly to the operators of such services  and is subject to those operators’ policies, if any, governing privacy  and security, even if accessed through the Service. We are not  responsible for the content or privacy and security practices and  policies of third-party sites or services to which links or access are  provided through the Service. We encourage you to learn about third  parties’ privacy and security policies before providing them with  information.</p>

                        <p>The following topics will be covered in this Privacy Statement:</p>

                        <p>To which companies does this Privacy Statement apply?</p>
                        <p>What is Personal Information?</p>
                        <p>How do we collect your Personal Information?</p>
                        <p>How do we use your Personal Information?</p>
                        <p>To whom do we provide your Personal Information?</p>
                        <p>When and how do we obtain your consent?</p>
                        <p>How long will we utilize, disclose or retain your Personal Information?</p>
                        <p>What are your rights regarding your Personal Information?</p>
                        <p>What safeguards have we implemented to protect your Personal Information?</p>
                        <p>How do we use your location services?</p>
                        <p>How do you contact us regarding access to your Personal Information or our privacy practices?</p>

                        <p>1. To which companies does this Privacy Statement apply?
                        This Privacy Statement applies to the collection, use and disclosure of Personal Information by the Company.</p>

                        <p>2. What is Personal Information?
                        “Personal Information” is any information that is identifiable with  you, as an individual. This information may include but is not limited  to your name, company, mailing address, e-mail address, billing address  and credit card information.</p>

                        <p>3. How do we collect your Personal Information?
                        We will always collect your Personal Information by fair and lawful  means (for example, when you register with us). We may collect Personal  Information from you directly and/or from third parties, where we have  obtained your consent to do so or as otherwise required or permitted by  law. The legal bases for our processing of Personal Information are  primarily that the processing is necessary for providing the Service and  that the processing is carried out in our legitimate interests (as more  fully described in question 4 below). We may also process Personal  Information upon your consent, asking for it as appropriate.
                        We may collect standard internet log information about how and when  you use our Service. This information may include but, not be limited  to, your IP address and location data, weblogs, time, date, browser  used, referring web addresses, other communication data, searches  conducted, and/or pages visited.
                        In addition, to ensure that our Service is optimized for ease of use,  we or our service provider(s) may use cookies to collect standard  information about your use of our Service, including your language  preferences, your browser type and version, and similar information.  These cookies are intended to optimize your experience of our Service.</p>

                        <p>4. How do we use your Personal Information?
                        We identify the purposes for which we use your Personal Information  at the time we collect such information from you and (if applicable)  obtain your consent, in any case, prior to such use. We generally use  your Personal Information for the following purposes (the “Purposes”):
                        to provide you with requested Services;
                        to develop and maintain strong business relationships with customers, partners and potential customers and partners;
                        to manage your use of Midnight Noise;
                        to respond to questions, comments or concerns regarding the Company or Midnight Noise;
                        to provide you with information about conferences and events;
                        to use Google Analytics to better understand our web traffic and marketing effectiveness (whose privacy policy can be found at https://policies.google.com/technologies/partner- sites. 
                        to allow for more meaningful and useful sales and marketing  initiatives, for example, your physical location while using our App may  be used to provide you with personalized offers, rewards, and  suggestions about new shops or other information in your area;
                        to collect opinions and comments in regard to the Company and Midnight Noise;
                        to perform demographic analysis for planning purposes (but only on an aggregate and anonymous basis);
                        to recruit for positions at the Company and for other employment-related tasks;
                        to investigate legal claims;
                        such purposes for which the Company may obtain consent from time to time; and
                        such other uses as may be permitted or required by applicable law.</p>

                        <p>5. To whom do we provide your Personal Information?
                        We identify to whom, and for what Purposes, we disclose your Personal  Information, at the time we collect such information from you and (if  applicable) obtain your consent to such disclosure.
                        We may disclose your Personal Information to third party service  providers with whom we have a contractual agreement that includes  appropriate privacy standards, where such third parties are assisting us  with the Purposes – such as service providers that provide telephone  support, data storage or processing, or hosting. When we disclose your  Personal Information to third parties, we take reasonable measures to  ensure that the rules set forth in this Privacy Statement are complied  with and these third parties provide sufficient guarantees to implement  appropriate technical and organisational measures.
                        Your Personal Information may be stored and processed in any country  where we have facilities or in which we engage third party service  providers. By using the Service, you consent to the transfer of  information to countries outside your country of residence, which may  have different data protection rules than in your country. While such  information is outside of Canada, it is subject to the laws of the  country in which it is held, and may be subject to disclosure to the  governments, courts or law enforcement or regulatory agencies of such  other country, pursuant to the laws of such country. However, our  practices regarding your Personal Information will at all times continue  to be governed by this Privacy Statement and, if applicable, we will  comply with the GDPR requirements providing adequate protection for the  transfer of Personal Information from the EU/EEA to a third country. For  written information about our policies and practices regarding service  providers outside of Canada, contact our Privacy Information Officer  (see question 10 below).
                        </p>
                        <p>6. When and how do we obtain your consent?
                        Under Canadian Privacy Laws, we generally obtain your consent prior  to collecting, and in any case, prior to using or disclosing your  Personal Information for any Purpose. You may provide your consent to us  either orally, electronically or in writing. Under Canadian Privacy  Laws, the form of consent that we seek, including whether it is express  or implied, will largely depend on the sensitivity of the Personal  Information and the reasonable expectations you might have in the  circumstances.
                        </p>
                        <p>7. How long will we utilize, disclose or retain your Personal Information?
                        We may keep a record of your Personal Information, correspondence or  comments, in a file specific to you. We will utilize, disclose or retain  your Personal Information for as long as necessary to fulfill the  purposes for which that Personal Information was collected and as  permitted or required by law. If you would like further information  regarding the periods for which your Personal Information will be kept,  contact our Privacy Information Officer (see question 10 below). 
                        </p>
                        <p>8. What are your rights regarding your Personal Information?
                        On written request and subject to proof of identity, you may access  the Personal Information that we hold, used or communicated and ask that  any necessary corrections be made, where applicable, as authorized or  required by law. However, to make sure that the Personal Information we  maintain about you is accurate and up to date, please inform us  immediately of any change in your Personal Information by mail or e-  mail.
                        Under the GDPR, you may be entitled to additional rights, including:  (i) the right to withdraw consent to processing where consent is the  basis of processing; (ii) the right to access your Personal Information  and certain other supplementary information, under certain conditions;  (iii) the right to object to unlawful data processing, under certain  conditions; (iv) the right to erasure of Personal Information about you,  under certain conditions; (v) the right to demand that we restrict  processing of your Personal Information, under certain conditions, if  you believe we have exceeded the legitimate basis for processing,  processing is no longer necessary, or believe your Personal Information  is inaccurate; (vi) the right to data portability of your Personal  Information that you provided us in a structured, commonly used, and  machine-readable format, under certain conditions; (vii) the right to  object to decisions being taken by automated means which produce legal  effects concerning you or similarly significantly affect you, under  certain conditions; and (viii) the right to lodge a complaint with data  protection authorities. If you want to learn more about your rights  under the GDPR, you can visit the European Commission’s page on Data  Protection at:  http://ec.europa.eu/justice/data-protection/index_en.htm.  Please note that the term Personal Information used in this Privacy  Statement is equivalent to the term “personal data” under the GDPR and  other applicable European data protection laws.
                        The Service is NOT directed to children under the age of 18, and we  do not knowingly collect Personal Information from children under the  age of 18 without obtaining parental consent. If you are under 18 years  of age, then please do not use or access the Service at any time or in  any manner. If we learn that Personal Information has been collected on  the Service from persons under 18 years of age and without verifiable  parental consent, then we will take the appropriate steps to delete this  information. If you are a parent or guardian and discover that your  child under 18 years of age has provided us with Personal Information,  then you may alert us as set forth in question 10 and request that we  delete that child’s Personal Information from our systems.
                        </p>
                        <p>9. What safeguards have we implemented to protect your Personal Information?
                        The only employees who are granted access to your Personal  Information are those with a business ‘need- to-know’ or whose duties  reasonably require such information. We have implemented physical,  organizational, contractual and technological security measures to  protect your Personal Information from loss or theft, unauthorized  access, disclosure, copying, use or modification. However, no method of  transmission over the Internet, or method of electronic storage, is 100%  secure. We cannot ensure or warrant the security of any information you  transmit to us or store on the Service, and you do so at your own risk.  We also cannot guarantee that such information may not be accessed,  disclosed, altered, or destroyed by breach of any of our physical,  technical, or administrative safeguards. If you believe your Personal  Information has been compromised, please contact us as set forth in  question 10. If we learn of a security systems breach, we will inform  you and the authorities of the occurrence of the breach in accordance  with applicable law.
                        </p>
                        <p>10. How is your location shared with others?
                        You have the option to share your location with other users and to see other user locations only if they are sharing as well. Location sharing can be turned on by accessing your Profile section in the App and turning on the Road Sharing mode toggle. This toggle must be turned on each time you shut down the App. Minimizing the App will hide your location temporarily until you open the App again unless you turn the toggle off first and then minimize. Sharing your location is required in order to use some features. 
                        </p>
                        <p>11. How do you contact us regarding access to your Personal Information or our privacy practices?
                        If you have any questions or comments about this Privacy Statement or  your Personal Information, to make an access or correction request, to  exercise any applicable rights, to make a complaint, or to obtain  information about our policies and practices with respect to any service  providers outside Canada, our Privacy Information Officer can be  reached by mail or email using the following contact information:
                        In writing: Attn: Privacy Information Officer</p>
                        <ul>
                        <li>Address: </li>
                        <li>Midnight Noise Inc. </li>
                        <li>333 Bay Street, Suite 2400 </li>
                        <li>Toronto, ON M5H 2T6 </li>
                        <li>By e-mail: midnightnoiserbg@gmail.com</li>
                        </ul>
                        </Tab>                        
                        <Tab eventKey="homprofilee" title="Terms of Use">

                        <p>Last Update: November 22nd, 2020</p>

                        <p>Midnight Noise Inc. Event Participation Agreement</p>

                        <p>The Midnight Noise Inc.(MN) Event Participation Agreement is to be used in conjunction with the Midnight Noise Inc. Terms and Conditions of Use as shown in the section below.</p>

                        <p>00. I understand by participating in MN Event(s) (Events), Midnight Noise Inc. and any affiliates and or sponsors are not responsible for any of my actions or others actions during the scheduled events. This includes but is not limited to: damages, injury, death or any form, and or act of God, weather, world events.</p>

                        <p>01. All drivers must have valid drivers license and insurance. MN is a drive at your own risk Event host and assumes no liability for your or other driver's actions during the event. We expect everybody to drive safely at all times. MN also asks that you obey the rules of the road while participating in all Events (obeying posted speed limits, construction, IDOT signs, police, pedestrians and other drivers.) MN reserves the right, at its own discretion, to disqualify and permanently ban any members who break these rules and MN will not issue any refunds in such circumstances. Winners will be judged on their total reputation points accumulated for completing each checkpoint challenge. All challenges are skill based and you are required to use your own mobile device to complete Events. These are family friendly Events. </p>

                        <p>02. Please follow the rules below:</p>

                        <p>Do not park in handicap spots unless legally permitted</p>
                        <p>No burnouts</p>
                        <p>No speeding</p>
                        <p>No street racing</p>
                        <p>No revving</p>
                        <p>No Solicitation</p>
                        <p>No Product Sales or Demos</p>
                        <p>No shop displays, banners, or branded vehicles allowed without MN's approval </p>

                        <p>03. Although our Events can be enjoyed completely from within your vehicle, if you do decide to get out and mingle please practice social distancing guidelines in your country and/or state/province, wear a mask, bring sanitizer, do not come if sick or if you've been around anyone sick. By joining Events you voluntarily assume all risks related to exposure to COVID-19.All Events are skill based. You are required to download the Midnight Noise mobile application (available on iOS and Android only) to participate in Events. </p>

                        <p>04. A prize winner includes any participant who successfully collects the most Reputation Points in the Midnight Noise Application during the Event only.​. RP are accumulated by successfully completing each in-app checkpoint and/or challenges. Arriving too early or too late to each checkpoint can deduct RP. Any RP accumulated outside of the Event time is not applicable. Event results will be posted on the Midnight Noise website. </p>

                        <p>05. Tiebreakers can include any of the following and will be judged by MN staff: Additional in-app or real world challenges which must be completed within 1 week of Event. </p>

                        <p>06. Photo and Video submissions are judged by the MN staff on whether the submission meets the requirements which were set out for that challenge. </p>

                        <p>07. You are required to use the Midnight Noise Application in order to participate. The application can be downloaded from Google Play for Android devices, and from Apple's App store for iOS. The application is not available on any other operating systems. </p>

                        <p>08. Liability: The winner also agrees to sign a standard release and waiver of liability prior to claiming the prize, absolving Midnight Noise Inc. of all its obligations under the Event and accepting the Prize as awarded as full and final satisfaction of all items owed to him/her by Midnight Noise Inc. </p>

                        <p>09. Additional information or third party app downloads may be required to participate in Events. For example, if we need you to download a communication app such as Discord. Perhaps we have technical issues with a service we are using within the MN app and require the use of another to ensure Event operation goes smoothly. </p>

                        <p>010. Participants must be age of majority in the state in which you reside to participate in Events. </p>

                        <p>011. Refund Policy: Refunds can be requested up to 30 days before Event day. For all other requests regarding refunds, please contact us midnightnoiserbg@gmail.com </p>

                        <p>012. Prizes will be paid to Event participants within 7 days of Event end time via wire transfer or other method to which both parties must agree. Event participants are required to provide any information requested by MN to complete the transfer within 72 hours of the request being sent. Event participants are responsible to provide true and accurate personal and contact information. Event participants are required update any outdated information or to notify MN at midnightnoiserbg@gmail.com before attending Events. There are no prize substitutes. Prizes must be collected within 14 days of Event day, otherwise MN reserves the right to pass down the prize to the runner up of the same Event. </p>

                        <p>013. It is your responsibility to ensure your vehicle is operating optimally. We are not responsible for any vehicle issues or malfunctions. </p>

                        <p>014. While not required, we recommend using devices which are less than 3 years old for best performance and location accuracy. You are solely responsible to ensure your device is working properly before every Event. </p>

                        <p>015. In special circumstances, we may need to cancel, postpone, or make changes to an event at a moment's notice due to unforeseen circumstances such as natural disasters, terror threats, and any other extreme event. There will be no refunds, however participants will get to use their ticket at any upcoming events but the ticket must be used within 2 months of the first booked event. </p>
                        
                        <p>Last Update: September 20th, 2019 </p>

                        <p>Midnight Noise is a mobile app developed by Midnight Noise Inc.  (the “Company,” “we,” or “us”), that is made available to you via the  Midnight Noise mobile application (the “App”), for which we provide  further information at: https://www.midnightnoise.com/ (the “Site,”  collectively, with the App, the “Service”). Please read all guidelines  made available to you by the Company or our third party suppliers (the  “Guidelines”) and these terms and conditions of use as set out below  (collectively, with the Guidelines, the “Terms”) carefully before using  the Service. The Terms govern your access to and use of this Service,  including the messages, information, data, text, software, images and  other content that make up this Service (the “Content,” which content is  part of this Service). The Terms (in addition to any other terms  provided to you at the time of purchase or participation, as applicable)  also govern your purchase of any Company merchandise and your  participation in any Events (as defined in Section 16). Please note  these Terms exempt the Company and others from liability and/or limit  our and their liability and contain other important provisions that  apply to your use of this Service. </p>

                        <p>1.    Disclaimer 
                        PLEASE NOTE  MIDNIGHT NOISE IS NOT A GAME NOR IS IT INTENDED TO PROMOTE OR ENDORSE  ANY ILLEGAL OR DISTRACTING ACTIVITY WHILE OPERATING A VEHICLE. WE DO NOT  PROMOTE OR ENDORSE ANY USE OF THE SERVICE THAT INFRINGES OR OTHERWISE  VIOLATES ANY APPLICABLE LAWS. THE SERVICE IS DEVELOPED PRIMARILY TO  ALLOW YOU TO CONNECT WITH OTHER VEHICLE AND MOTORCYCLE OWNERS IN YOUR  CITY. NO PERSON OR ENTITY ASSOCIATED WITH THIS SERVICE HAS RECEIVED  PAYMENT OR ANYTHING OF VALUE, OR ENTERED INTO ANY AGREEMENT, IN  CONNECTION WITH THE USE OF ANY VEHICLE OR ITS RELATED PRODUCTS.
                        </p>
                        <p>2.    Terms of Use
                        Your  use of this Service is conditional on your acceptance of these Terms.  By visiting or using this Service you agree on your own behalf, and on  behalf of any organization on whose behalf you may act (collectively  referred to herein as “you”), to accept and abide by these Terms for  each use of and each visit to this Service. 
                        If you do not agree to  abide by these Terms, you cannot use this Service or register to receive  information about the Service. You agree to have these Terms and any  related information made available to you, and to otherwise have  communications between you and us occur, electronically. 
                        We have the  right, in our sole discretion, to add to, remove, modify or otherwise  change any part of these Terms, in whole or in part, at any time. If we  exercise this right, the “Last Update” notice at the top of this  document shall be amended to reflect the last date of such changes.  Changes will be effective as of the date the changes to these Terms are  posted to the Service or communicated to you by us. You acknowledge and  agree that the Company may, but is not obligate to provide notice to you  via the App when we update these Terms. However, it is your  responsibility to check these Terms each time you access this Service to  determine whether any changes have been made, including by checking the  date of the “Last Update” at the top of these Terms. If any change to  these Terms is not acceptable to you, you must discontinue your use of  this Service immediately and uninstall the App from your mobile device.  Your continued use of this Service after any such changes are posted  will constitute acceptance of those changes. These Terms apply  exclusively to your use of this Service and do not alter the terms or  conditions of any other agreement you may have with us.
                        We may, at  any time without notice or liability, and for any reason whatsoever,  terminate, change, suspend or discontinue any aspect of this Service,  including: (i) changing the availability of, restricting access to, or  imposing limits on any or all features or services on, or links to, this  Service; (ii) removing, adding, modifying or otherwise changing any  fees or charges arising out of use of this Service or any features of  this Service; and (iii) removing, adding, modifying or otherwise  changing any Events or Content on this Service. We reserve the right, in  our sole discretion, to correct any errors or omissions in any portion  of this Service at any time without notice, but confirm that we have no  duty to do so.
                        You must be 18 years of age or older in the jurisdiction in which you reside as of the time you register with us.
                        The  Service is NOT intended for children under the age of 18, and they are  not permitted to use the Service. We strongly encourage all parents and  guardians to monitor the Internet use by their children. If you use the  Service, you affirm you are at least 18 years old.
                        Any mobile  application downloaded via a third party store, such as the App Store or  Play Store, may be subject to additional terms and conditions  applicable to such store. You acknowledge and agree that the owner of  such third party store is not a party to these Terms. 
                        </p>
                        <ul> 3.    Limited Licenses
                        Subject  to these terms, you are granted a non-exclusive, non-transferable,  non-sub-licensable, revocable, limited right and license to use this  Service, including the Content therein accessible through this Service,  solely for your own personal use or your business use if you are an  Organization (as defined below). 
                        You may not, nor may you cause or assist another to:
                        <li>⦁     modify, reverse engineer, decompile, create derivative works from, or  disassemble this Service for any reason whatsoever, including for the  purpose of creating competitive products or services; </li>
                        <li>⦁    resell any Content or include any Content in or with any product that you create or distribute; </li>
                        <li>⦁     copy or store any Content onto your own or any other web site or into a  database or mobile application, except that (i) you may download,  install and store a copy of the App on your mobile device, and (ii) your  computer, browser, or mobile device may temporarily store or cache  copies of materials being accessed and viewed; </li>
                        <li>⦁    use this Service  in any manner that could damage, disable, overburden, impair, interfere  with the security of, negatively affect the functioning of, or  otherwise abuse, this Service or any other services, system resources,  accounts, servers, networks, affiliated or linked sites connected to or  accessible through this Service (including without limitation uploading,  posting or otherwise transmitting on this Service any computer viruses,  trojan horses, worms or other files or computer programs which are  potentially harmful, disruptive or destructive or that may impose an  unreasonable or disproportionately large load on the infrastructure of  this Service); </li>
                        <li>⦁    use this Service for commercial purposes or  activities, including, without limitation, sending unauthorized or  unsolicited junk mail, spam, chain letters, pyramid schemes or any other  form of solicitation; </li>
                        <li>⦁    use any robot, spider or other automatic  program or device, or manual process to monitor, copy, summarize, or  otherwise extract information from this Service or the Content in whole  or in part; </li>
                        <li>⦁    post any content to the Service that may infringe  upon any third party copyright, registered patent(s), proprietary rights  or Third Party Marks (as defined in Section 9); </li>
                        <li>⦁    use the Service with multiple accounts for the Service; </li>
                        <li>⦁    share your User (as defined in Section 7) account with any other person; </li>
                        <li>⦁    use any techniques to alter or falsify your device’s location (for example through GPS spoofing); </li>
                        <li>⦁    sell or trade your User (as defined in Section 7) account to any other person; </li>
                        <li>⦁     use this Service in any manner that may dilute or depreciate our name  or reputation, our Marks (as defined in Section 9) or our affiliates or  associates; or </li>
                        <li>⦁    interfere with any other persons’ use and enjoyment of this Service or of the Internet generally. </li>
                        To the extent you are in breach of your obligations under these Terms, the  Company may investigate occurrences which may involve violations of  applicable laws and co-operate with law enforcement authorities in  prosecuting persons who are involved in such violations. We reserve the  right at all times to disclose any information (including without  limitation your Personal Information or your identity) regarding your  usage of this Service (including any perceived violations of applicable  law), in each case as may be permitted by or required to satisfy  applicable law.
                        </ul>
                        <p> 4.    GPS Services 
                        The Services may allow you to  use certain navigation or GPS features. When you use such features of  the Services including any map data, traffic data, directions, and other  Content, you may find that actual conditions differ from those set out  in the Services or the Content. Exercise your independent judgment and  use the Services at your own risk and with caution. You are responsible  at all times for your conduct and its consequences when using the  Services.
                        </p>
                        <p> 5.    Safety and Appropriate Use 
                        ⦁    While you are  using the Service, please be aware of your surroundings, drive and  communicate safely. You agree that your use of the Service is at your  own risk, and that you will not use the Service to violate any  applicable law, regulation, Event policies, or instructions as outlined  in these Terms or the App, and you will not encourage or enable any  other individual to do so.
                        ⦁    Further, you agree that in  conjunction with your use of the Service you will not make available any  unlawful, inappropriate, or commercial Content. You agree that you will  not submit inaccurate, misleading, or inappropriate Content, including  data submissions, edits, or removal requests.
                        ⦁    When using the  Service please exercise caution and appropriate judgment. The Company  does not intend for the Services to be used as a distraction while  operating a vehicle. You agree that at all times when using the Service  you will exercise caution and will follow all laws applicable to you and  the operation of your vehicle.
                        </p>
                        <p> 6.    Events and Interacting with other Users
                        You  agree that in conjunction with your use of the Service, you will  maintain safe and appropriate contact with other Users and other people  in the real world. You will not harass threaten or otherwise violate the  legal rights of others. You will not trespass, or in any manner attempt  to gain or gain access to any property or location where you do not  have a right or permission to be, and will not otherwise engage in any  activity that may result in injury, death, property damage or loss,  nuisance, or liability of any kind. 
                        </p>
                        <ul> 7.    Membership and Accounts
                        To  use the App (to be a “User”), you must download the App and register  for a Midnight Noise account. To download the App and to become a User: 
                        <li>⦁     You must download the App from our Site or through a third party  service provider (such as the App Store or Play store) by providing your  real name, valid email address and any other information requested by  us or such third party service provider. Any Personal Information (as  defined in our Privacy Statement) which the Company collects via the  Service or is provided to the Company by a third party service provider  is subject to our Privacy Statement (as defined in Section 11). You  hereby acknowledge that the collection, use and disclosure of your  Personal Information pursuant to the Privacy Statement is for reasonable  and appropriate purposes, and is with your knowledge and consent. </li>
                        <li>⦁     Upon downloading the App to your device, you must either: (i) create a  unique  and secure User name and password; or (ii) login via the third  party service providers set out on the login page of the App, and each  case enter your vehicle details and, the country, city and  state/province you reside in. At all times during your use of this  Service, you must provide complete and accurate information to us and  notify us if your information changes. </li>
                        <li>⦁    You may not share your  User name or password with any other person. You are responsible for all  activity occurring under your User account (including any fraud or  counterfeiting of fiat currency), and shall abide by all applicable  local, state, provincial, national and foreign laws, treaties and  regulations in connection with your use of the Service. </li>
                        <li>⦁    You  shall: (i) notify us immediately of any unauthorized use of any password  or User account or any other known or suspected breach of security; and  (ii) not impersonate another person or provide false identity  information to gain access to or use the Service. </li>
                        <li>⦁    Without  limitation, we may immediately remove or suspend any User account that  breaches any provisions of this Terms (including Section 3(c)) or falls,  or is suspected of falling, under the scope of the foregoing paragraphs  7(d) (i) or (ii). </li>
                        <li>⦁    If you become aware of an unauthorized access  to your account, or ought to have become aware of such access, you must  change your password and notify us immediately. You agree to notify us  promptly of any unauthorized use of your password and you will remain  liable for any use of this Service to the extent permitted by law. </li>
                        <li>⦁     You must not use this Service in any manner that is unlawful  (including by accessing this Service from any location where such access  may be illegal or prohibited), unethical, indecent, offensive,  defamatory, derogatory, fraudulent, deceptive, harmful, abusive,  threatening, vulgar, profane, pornographic, obscene, sexually explicit,  sexist, racist, hateful, offensive, harassing, invasive of the privacy  rights of others (this includes the posting of any materials that  depict, encourage, indicate, advocate or tend to incite any such  conduct), or is otherwise objectionable or which does not respect the  legal rights and interests of others. </li>
                        Following any or all of the  steps set out in this Section 7 or providing your information to the  Company does not guarantee that you will be granted access to use the  Service or that you will able to effectively use the Service. 
                        </ul>
                        <p> 8.    Transaction Terms 
                        ⦁     Access to certain services on the App is contingent upon your payment  of fees, and such fees are set out prior to you making any such in-App  purchases (as amended from time to time). Please note that prices and  charges associated with this App are set out in the currency of the  jurisdiction where you download the App.
                        ⦁    Payments (as well as  any processing fee charged to the Company by our third party payment  processor, if applicable) will be processed on a per transaction basis,  on behalf of the Company by a third party payment processor using their  secure site. Upon submitting your order your information will be sent to  the third party payment processor. Personal Information that you submit  during the payment process is subject to our Privacy Statement and that  of the third party payment processor. 
                        ⦁    You are responsible for  and agree to pay promptly, all charges to your User account, including  applicable taxes on use by you or anyone you allow to use your account  and password to download the App. You agree and accept responsibility  for keeping all your account information current, including address,  payment information, telephone number, and any contact details. 
                        ⦁     The Company reserves the right to change the prices, fees and charges  associated with the Service at any time and from time to time without  any notice or any liability to you or any other person. Our third party  payment processor reserves the right to limit the Service provided to  any User. The Company also reserves the right at any time to reject,  cancel or terminate any transaction. 
                        </p>
                        <p> 9.    Proprietary Rights
                        This  Service is protected by Canadian copyright laws and treaty provisions.  Any unauthorized copying, redistribution, reproduction or modification  of this Service (including any element of the Content) by any person may  be a violation of trade-mark and/or copyright laws and could subject  such person to legal action. You agree to comply with all copyright laws  worldwide in your use of this Service and to prevent any unauthorized  copying, redistribution, reproduction or modification of this Service or  any of the Content.
                        Certain names, graphics, logos, icons, designs,  words, titles and phrases on this Service, including without limitation  “Midnight Noise,” constitute trade-marks, trade names, trade dress and  associated products and services of the Company or its affiliates (the  “Marks”) or constitute trade-marks, trade names, trade dress and  associated products and services of the Company suppliers or other third  parties (the “Third Party Marks”) and are protected in Canada and  internationally and their display on this Service does not convey or  create any licence or other rights in the Marks or the Third Party  Marks. Any use of the Marks or the Third Party Marks, in whole or in  part, without prior written authorization of the Company or such third  party, as applicable, is strictly prohibited.
                        </p>
                        <p> 10.    Linked Services
                        Certain  links on this Service or in User Content made or shared through this  Service may take you to other web sites that are not owned or operated  by the Company. the Company provides these links only as a convenience.  the Company is not responsible for the content of any such linked web  sites. the Company makes no representation or warranty regarding, and  does not endorse, such linked web sites, the information or other  content appearing thereon or any of the products or services available  on or through such web sites. If you decide to visit any linked site,  you do so at your own risk and it is your responsibility to take all  protective measures to guard against viruses and other destructive  elements. We welcome you to link to this Service. However, you agree  that if you link to this Service your website shall not (a) alter the  visual presentation of this Service; (b) imply that the Company or  Midnight Noise is endorsing you or any other person, or your or such  other person’s products or services; (c) imply an affiliation between  you or any other person, or your or such other person’s products or  services, and the Company or Midnight Noise without the prior written  consent of the Company; (d) misrepresent the relationship of you or any  other person with the Company or Midnight Noise or present false,  misleading or otherwise damaging information or impressions about the  Company or Midnight Noise or any of its products or services; or (e)  contain materials that may be illegal or interpreted as distasteful,  harmful, offensive, or inaccurate.
                        We may allow for certain  functionality that interacts with social media platforms, including but  not limited to, Facebook, Twitter, Instagram and Twitch that enable  online sharing and collaboration among users who have registered to use  them. Any use of such social media platforms including, postings that  you make, or Personal Information that you make available on any such  social media platforms, is subject to the terms of use and privacy  policies of those platforms. Please refer to those social media  platforms to better understand your rights and obligations with regard  to such terms and policies.
                        You acknowledge and agree that in no  event will the Company provide any form of remuneration for any links  you may make to this Service.
                        </p>
                        <p> 11.    Privacy Statement
                        To provide  the Service, we need to collect information about you, and we only use  your information where we have a legal basis to do so. Any Personal  Information which the Company collects via this Service or indirectly  via third party service provider is subject to the privacy statement  available at https://midnightnoise.com/pages/privacy-policy (the  “Privacy Statement”). As such, you hereby consent and agree to the  collection, use, storage and disclosure of your information in  accordance with our Privacy Statement. Our Privacy Statement does not  govern use of information that you provide to third parties, such as  Meet organizers and other Users of this Service.
                        In addition, when  you interact with this Service, you agree that information about you and  your use of this Service, including but not limited to, the type of  device, your mobile carrier, your internet access provider, your  physical location, and/or web pages containing plugins that load in your  browser may be communicated to us.
                        </p>
                        <p> 12.    Location Based Tracking 
                        You  acknowledge and agree that your geo-location information must be  provided via your device in order for the Service to function  effectively. You acknowledge and agree that: (a) your geo-location  information will be monitored and tracked by the App when you are logged  into the App; and (b) the approximate location of your vehicle will be  displayed on the App during your use of same. In addition, the Company  may monitor, track and share your geo-location information obtained by  the App for safety, security, technical, marketing and commercial  purposes, including to provide and improve the Services and any of our  other products and services. If you choose to share your geo-location  with any other Users, you acknowledge and voluntarily choose to assume  the risk associated with doing so.
                        </p>
                        <p> 13.    User Communications
                        Any  non-identifiable information gathered by us through your use of this  Service may be used by us for our own marketing, promotional and product  development purposes and more specifically may be stored in a database  and used by us to identify, customize and personalize user access, and  assess utilization of this Service. Such information may be shared with  our affiliates, suppliers, licensors, partners and clients in  furtherance of the forgoing purposes. 
                        </p>
                        <p> 14.    User Submissions (solicited and unsolicited) and Content
                        ⦁    Subject to any applicable law and the requirements of our Privacy Statement:
                        ⦁     We do not wish to obtain unsolicited ideas, including ideas for new  advertising campaigns, new promotions, new or improved products, goods,  services or technologies, product enhancements, processes, materials,  marketing plans, or new product names. Accordingly, please do not send  any unsolicited ideas, suggestions or other materials (“Submissions”) to  us.
                        ⦁    Any communications sent by you to us via this Service or  otherwise, including Submissions, whether solicited by us or otherwise,  are on a non-confidential basis (other than Personal Information which  is covered under our Privacy Statement and User Content), and we are  free to use and disclose the content of any such communication,  including without limitation any ideas, inventions, concepts, techniques  or know-how disclosed therein, for any purpose including without  limitation developing, manufacturing and/or marketing goods and  services. You agree to not assert any ownership right of any kind in  such communications (including without limitation copyright, trade-mark,  patent, trade secret, unfair competition, moral rights, or implied  contract) and you hereby waive such moral rights in favour of the  Company and its affiliates, licensees, successors and assignees. You  also acknowledge that you have no right to receive any financial or  other consideration in connection with such communication. 
                        ⦁    We  are not obliged to monitor, screen, police or edit your use of this  Service, including any User Content or other content you or others may  contribute to this Service, although we may choose to do so in our sole  discretion. We will respond as we consider appropriate, in our sole  discretion, if we become aware of any inappropriate uses of this  Service, including without limitation use that constitute copyright  infringement.
                        </p>
                        <ul> 15.    User Content
                        <li>⦁    “User Content” means any  content including, messages, information, data, text, software, images  and other content a User of the Service provides to the Company or  otherwise makes available through Service. </li>
                        <li>⦁    The Company does not  claim ownership rights in User Content and nothing in these Terms  restricts any rights that you may have to use and exploit your User  Content. By making any User Content available through the Service you  grant to the Company a nonexclusive, transferable, sublicensable,  worldwide, royalty-free, perpetual license (or, if not permitted under  applicable law, a license for the whole duration, including for any  extension thereof, of all relevant rights under any applicable law), to  use, copy, modify, create derivative works based upon, publicly display,  publicly perform, market, promote and distribute your User Content in  connection with operating and providing the Service and Content to you  and to others. By accepting these terms, you allow The Company to  benefit freely from the above rights, including but not limited to: </li>
                        <li>⦁    The right to reproduce User Content by any means and in any form. </li>
                        <li>⦁     The right to publicly or privately broadcast or make available the  User Content (or any product incorporating the User Content), in return  for payment or free of charge in all places by any means or process  known or unknown at the present time, and in particular via the  Internet, pay per view, pay per play, theatrical or television  broadcasting, and print. </li>
                        <li>⦁    The right to use the User Content for demonstration, promotion and advertising for the Company or any of its services. </li>
                        <li>⦁     The right to produce or order the production of any new product or  service from the User Content or from any product incorporating or  exploiting the User Content, either reproduced as it stands or modified  by the Company or by any outside party of its choice. </li>
                        <li>⦁    You are  solely responsible for all your User Content. You represent and warrant  that you own all your User Content or you have all rights that are  necessary to grant us the license rights in your User Content under  these Terms. You also represent and warrant that neither your User  Content, nor your use and provision of your User Content to be made  available through the Service, nor any use of your User Content by the  Company on or through the Service will infringe, misappropriate or  violate a third party’s intellectual property rights, or rights of  publicity or privacy, or result in the violation of any applicable law  or regulation. To the extent permitted by applicable law, you also agree  that you will not exercise your moral rights (or equivalent rights  under applicable laws), such as your right to be identified as the  author of any of the User Contents, against the Company or any third  party designated by the Company. </li>
                        </ul>
                        <p> 16.    Events 
                          The term  “Event(s)” means any in-person event, gathering, activity or promotion  which is directly organized, hosted, or managed by the Company, and any  Promotion (as defined below). By registering or, where required,  purchasing tickets for an Event, you represent and warrant that the  information you provide is true and accurate. If you are registering or  purchasing tickets on behalf of others, you represent and warrant you  have all necessary rights and consents to register and provide this  information for others.
                        Subject to applicable law and the exceptions  set forth in these Terms, no refunds or exchanges of Event tickets are  permitted and tickets are non-transferable. Reasonably acceptable proof  of identity, for example a driver’s license or passport, showing the  same first and last name as those provided at time of prior  registration, may be required to access an Event. Actual or attempted  resale of tickets subjects them to revocation without refund. Tickets  obtained from unauthorized sources may be invalid, lost, stolen, or  counterfeit and may not be honored. Tickets cannot be replaced if lost,  stolen or destroyed. Commercial use of tickets is prohibited without  written approval from the Company. Tickets are not redeemable for cash  or credit. You agree to abide by these Terms including and any other  published terms and conditions including those relating to ticket limits  or restrictions. Orders exceeding or violating any applicable terms and  conditions including those set out herein are subject to cancellation  without notice or refund. Events may have limited space and/or  availability and the Company does not guarantee your ability to purchase  a ticket or attend an Event.
                        </p>
                        <p> 17.    Meet
                        The term “Meet(s)” means  any in-person event, gathering, activity or promotion which is  organized, promoted or managed by you or another User through the  Service. By registering, attending or, where required, purchasing  tickets for any Meets, you acknowledge that some Meets carry inherent  dangers, such as the risk of property damage or loss, illness, bodily  injury, disability, or death. By participating in any Meets, you  understand and agree that you have freely chosen to assume these risks.
                        To  the full extent permitted by applicable law, you agree to release the  Company Representatives (as defined in Section 24) from any claims,  losses, demands, and damages (direct and consequential), arising out of  or in any way connected with any transaction or interactions with a  third party or any Users, or in connection with any Meets. You also  agree, to the full extent permitted by applicable law, to release  organizers of Meets from any claims, losses, demands, and damages  (direct and consequential) based on an organizer’s negligence arising  out of or in any way connected with their content, or any Meet. 
                        </p>
                        <p> 18.    Community Guidelines and Conduct at Events and Meets 
                        You  shall at all times comply with our Community Guidelines and any  additional Guidelines, rules and policies provided by the Company or any  other authorized party involved in creating or delivering an Event or a  Meet including, all health and safety policies and procedures and all  reasonable instructions of the venue staff and the Company  representatives at the Event or Meet. In addition, you represent and  warrant that you shall comply with all applicable laws in relation to  any Event, Meet or your use of the App in connection with same. 
                        Illicit drugs, controlled substances, contraband, weapons and illegal items are  prohibited at Events and Meets. You agree and consent to all security  precautions and searches upon entry to any Events or Meets. To the  fullest extent permitted by applicable law, you waive and release the  Company and any other party involved in creating or delivering the Event  or Meet from any and all claims, demands, causes of action, damages,  losses, expenses or liability which may arise out of, result from, or  relate in any way to such security precautions and/or searches. If you  elect not to consent to such security precautions and searches, you may  be denied entry, or removed from, an Event or Meet without refund or  other compensation.
                        The Company, its authorized third parties or the  organizer, as applicable, reserve the right to refuse admission to, or  to remove you from an Event or Meet without refund or compensation of  any kind, any person that (a) does not comply with these Terms, (b)  engages in disorderly conduct or willful misconduct, or (c) the Company,  its authorized third parties, or the organizer, as applicable, believe  will cause a negative effect on the Event or Meet participants,  spectators, and/or personnel.
                        Any minor attending an Event or Meet must be accompanied by a parent or legal guardian.
                        </p>
                        <p> 19.    Assumption of Risk
                        Unless  prohibited by applicable law, you agree that by purchasing tickets to,  participating in or attending an Event or Meet, you willingly, knowingly  and voluntarily assume any and all risks occurring before, during or  after the Event or Meet, including injury by any cause and damage, loss,  or theft of property. You acknowledge that Events and Meets, and  certain activities occurring at same, have inherent and unforeseen  risks, including but not limited to (a) contact or collision with  persons or objects, (b) obstacles (e.g., natural and man-made water,  road and surface hazards), (c) equipment related hazards (e.g., broken,  defective or inadequate equipment, unexpected equipment failure), (d)  weather related hazards, (e) inadequate first aid and/or emergency  measures, (f) judgment and/or behavior related problems (e.g., erratic  or inappropriate participant, co-participant, or spectator behavior or  errors in judgment by personnel at the Event or Meet), and (g) natural  hazards (e.g., uneven or difficult terrain, wildlife and insects,  contact with plants). You agree to take reasonable precautions before  attending or participating in an Event or Meet and its activities, for  example consulting with a personal physician and ensuring you are in  good physical health, ensuring your vehicle's roadworthiness, and  bringing necessary or recommended supplies. You further understand and  acknowledge it is your responsibility to inspect the Event or Meet  location, facilities, equipment and areas to be used for same, and that  by participating in the Event or Meet, you acknowledge that the Event or  Meet location, facilities, equipment, and areas to be used for same are  safe, adequate, and acceptable for participation. If you believe or  become aware of any unsafe conditions or unreasonable risks, you agree  to immediately notify appropriate personnel and cease participation in  the Event or Meet.
                        To the extent permitted under applicable law, you  hereby waive and release the Company and any other party involved in  creating or delivering the Event or Meet from any and all claims,  demands, causes of action, damages, losses, expenses or liability which  may arise out of, result from, or relate in any way to your attendance  or participation in an Event or Meet, including for negligence, inherent  and unforeseen risks, injury to persons or damage or loss of property  and the actions of third parties or Event or Meet participants and  spectators.
                        </p>
                        <p> 20.    Cancellation of Events 
                        Subject to applicable  law, all schedules and any live or in-game experiences, activities,  goods, services, perks, items, rewards and/or Content (collectively  “Event Features”) advertised in connection with an Event are not  guaranteed and are subject to change and/or cancellation at any time  prior to or during an Event without notice or compensation of any kind.  Admittance to an Event does not guarantee any specific Event Features  while at the Event.
                        Event date, time and/or location are subject to  change at any time, and the Company will make a commercially reasonable  effort to notify you in advance of any material changes. If an Event is  canceled, suspended, or rescheduled and you are not able to attend, you  will not be entitled to any compensation other than a refund of the  ticket price at its face value with no further liability or compensation  from the Company or any other party. Any travel or accommodation costs  incurred are entirely your responsibility.
                        </p>
                        <p> 21.    Use of likeness 
                        You  consent to and approve of the Company recording your image, likeness,  name, dialogue, biographic information, personal characteristics, and  voice at Events and the royalty free use of this information subject to  the same rights granted to you in Section 15. the Company may publish  the results of any competitions (including rankings and any winners),  gameplay statistics, and pictures of participants in promotional and  marketing materials and on social media in accordance with these Terms.
                        </p>
                        <p> 22.    Virtual Points 
                        The  App may permit you to earn virtual points, tokens or blueprints  (“Points”), and use of those Points to access additional features, items  or services expressly available for use in the App (“Virtual Goods”).  Points are a category of Content. You may access the Virtual Goods for  your personal, non-commercial use on the Services. You acknowledge that  you do not acquire any ownership rights in or to the Points or Virtual  Goods. Any balance of Points or Virtual Goods does not reflect any  stored value and you agree that Points and Virtual Goods have no  monetary value and do not constitute currency or property of any type.  Points may be redeemed only for Virtual Goods and can never be sold,  transferred, or exchanged for “real” money, “real” goods, or “real”  services from us or anyone else. You agree that you will only obtain  Points and/or Virtual Goods from us and through means provided by us,  and not from any third party unless expressly authorized. Once you  acquire any Points or Virtual Goods, you may not transfer them to  another individual or account. Any such sale, transfer, or exchange (or  attempt to do so) is prohibited, is a violation of these Terms and may  result in cancellation of such Points or Virtual Goods or the  termination of your User account.
                        During the term of your license to  your Points, you may redeem your Points for selected Virtual Goods. As  set forth below, all Points, Virtual Goods, and other Content is  provided “AS IS,” without any warranty. You agree that all sales by us  to you of Points and Virtual Goods are final and that we will not permit  exchanges or refunds for any unused Points or Virtual Goods once the  transaction has been made.
                        Generally, we have the right to offer,  modify, eliminate, and/or terminate Points, Virtual Goods, the Content,  and/or the Services, or any portion thereof, at any time, without notice  or liability to you. If we discontinue the use of Points or Virtual  Goods, we will take reasonable steps provide advance notice to you by  posting a notice through the Services or through other communications.
                        </p>
                        <p> 23.    Disclaimer
                        We  make no representation or warranty and there are no conditions of any  kind regarding this Service, the Content, Points, Virtual Goods, Events  or User Content, including without limitation any representation,  warranty or condition regarding the accuracy, reliability, currency, or  completeness of the Content, Points, Virtual Goods, Events or User  Content, including that they are appropriate or available for use at any  locations outside Canada. Accessing the Service, the Content, Points,  Virtual Goods, Events or User Content, from locations where the  foregoing, are illegal, is prohibited. Any diversion of the services  and/or any Content, of the Content, Points, Virtual Goods, or User  Content obtained from or through the services contrary to Canadian law  is also prohibited. If you access this Service from locations other than  Canada, then you do so on your own initiative and you are solely  responsible for compliance with all applicable laws.
                        You acknowledge  and agree that this Service (including without limitation the Content  and User Content) is provided on an “AS IS” basis and that any use of or  reliance on this Service shall be at your sole risk. We do not screen  or censor User Content.
                        THE COMPANY AND ITS AFFILIATES DO NOT MAKE  ANY, AND EXPRESSLY DISCLAIM ALL, REPRESENTATIONS, WARRANTIES, COVENANTS  AND CONDITIONS, EXPRESS OR IMPLIED, BY OPERATION OF LAW OR OTHERWISE,  INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES AND CONDITIONS OF  MERCHANTABLE QUALITY, OR FITNESS FOR ANY PARTICULAR PURPOSE, OR  NON-INFRINGEMENT, OR THOSE ARISING OUT OF COURSE OF PERFORMANCE, COURSE  OF DEALING OR USAGE OF TRADE WITH RESPECT TO THIS SERVICE (INCLUDING THE  CONTENT, POINTS, VIRTUAL GOODS OR USER CONTENT). FOR GREATER CERTAINTY,  THE COMPANY DOES NOT WARRANT THAT THIS SERVICE WILL (I) MEET YOUR  REQUIREMENTS, (II) BE COMPATIBLE WITH YOUR COMPUTER OR MOBILE DEVICE OR  ANY RELATED EQUIPMENT, (III) BE RELIABLE, ACCURATE, AUTHENTIC, CURRENT  OR COMPLETE, (IV) CONTINUE TO OPERATE, (V) OPERATE WITHOUT  INTERRUPTIONS, (VI) BE ERROR-FREE OR (VII) BE FREE OF VIRUSES, WORMS,  DISABLING PROGRAMMING CODES, INSTRUCTIONS OR OTHER SUCH ITEMS KNOWN AT  THE TIME OF DELIVERY THAT MAY THREATEN, INFECT, DAMAGE, DISABLE OR  OTHERWISE INTERFERE WITH THE SERVICE. 
                        </p>
                        <p> 24.    Limitation of Liability
                        TO  THE FULL EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT IN NO EVENT  WILL THE COMPANY AND ANY OF ITS AFFILIATES OR THEIR RESPECTIVE  DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES  (COLLECTIVELY THE “COMPANY REPRESENTATIVES”) HAVE ANY RESPONSIBILITY OR  LIABILITY IN CONNECTION WITH THIS SERVICE, THE CONTENT, POINTS, VIRTUAL  GOODS, EVENTS, MEETS OR USER CONTENT FOR ANY LOSSES OR DAMAGES  WHATSOEVER, WHETHER BASED ON CONTRACT (INCLUDING FUNDAMENTAL BREACH OR  BREACH OF A FUNDAMENTAL TERM), TORT (INCLUDING NEGLIGENCE) OR OTHER  LEGAL OR EQUITABLE BASIS, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT,  SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR OTHER DAMAGES  (INCLUDING WITHOUT LIMITATION DAMAGES FOR INJURY OR BODILY HARM, HARM TO  BUSINESS, LOSS OF INFORMATION OR DATA, DAMAGE OR LOSS OF EQUIPMENT OR  PROPERTY, LOSS OF PROFIT, LOSS OF SAVINGS OR REVENUE, LOSS OF GOODWILL  OR OTHER ECONOMIC LOSS), ARISING FROM OR IN CONNECTION WITH OR RELATING  TO THE USE OF OR ACCESS TO, OR ANY INCONVENIENCE, DELAY OR LOSS OF USE  OF OR ACCESS TO, THIS SERVICE, THE CONTENT, POINTS, VIRTUAL GOODS,  EVENTS, MEETS (INCLUDING, WITHOUT LIMITATION, TRANSPORTATION TO OR FROM  ANY MEETS, ATTENDANCE AT MEETS, PARTICIPATION IN OR EXCLUSION FROM MEETS  AND THE ACTIONS OF YOU OR OTHERS AT MEETS) OR USER CONTENT OR ANY  CONTENT OF ANY LINKED WEB SITE, OR FAILURE OF SUCH WEB SITES (INCLUDING  WITHOUT LIMITATION ANY DAMAGES SUFFERED AS A RESULT OF OMISSIONS OR  INACCURACIES IN SUCH WEB SITES, CONTENT OR USER CONTENT, OR THE  TRANSMISSION OF CONFIDENTIAL OR SENSITIVE INFORMATION TO OR FROM SUCH  WEB SITES), EVEN IF WE OR ANY THE COMPANY REPRESENTATIVES HAVE BEEN  ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR LOSS OR IF SUCH DAMAGE OR  LOSS WAS OBJECTIVELY FORESEEABLE. 
                        IF UNDER ANY CIRCUMSTANCE A  COMPANY REPRESENTATIVE IS FOUND TO BE LIABLE TO YOU OR ANY THIRD PARTY  SUCH COMPANY REPRESENTATIVES AGGREGATE LIABILITY IN ANY SUCH  CIRCUMSTANCES IS LIMITED TO THE GREATER OF $100 OR THE AMOUNT OF FEES,  IF ANY, YOU PAID TO US IN THE 12 MONTHS PRIOR TO THE ACTION THAT MAY  GIVE RISE TO LIABILITY.
                        YOU ACKNOWLEDGE THAT THE COMPANY ACTS AS  TRUSTEE FOR THE COMPANY REPRESENTATIVES WITH RESPECT TO ALL RIGHTS  CONTEMPLATED HEREUNDER ARISING IN FAVOUR OF A COMPANY REPRESENTATIVE.  THE COMPANY AGREES TO ACCEPT SUCH TRUST AND HOLD AND ENFORCE SUCH RIGHTS  ON BEHALF OF EACH THE COMPANY REPRESENTATIVE.
                        </p>
                        <p> 25.    Indemnity
                        You  will indemnify and hold the Company Representatives harmless from and  against any claims brought by third parties arising out of your use of  this Service, Points or Virtual Goods and any breach of these Terms by  you, including any use of the Content or actions taken at Events, other  than as expressly authorized in these Terms. You agree that the Company  Representatives will have no liability in connection with any such  breach or unauthorized use or modification, and agree to indemnify  against any and all resulting loss, damages, judgments, awards, costs,  expenses, and lawyers’ fees of the Company Representatives in connection  therewith.
                        </p>
                        <p> 26.    Choice of law
                        You agree that all matters  relating to the access to, or use of, this Service shall be governed by  the laws of the Province of Ontario and the federal laws of Canada  applicable therein, without reference to its conflict of laws  principles, and that you will comply with all such applicable laws.
                        Any  dispute between us and you or any other person arising from, in  connection with or relating to this Service, these Terms, any  transaction through this Service or any related matters must be resolved  before the Courts of the Province of Ontario, Canada sitting in the  City of Toronto, and you hereby irrevocably submit and attorn to the  exclusive jurisdiction of those Courts in respect of any such dispute.  The application of 1980 United Nations Convention on Contracts for the  International Sale of Goods is expressly excluded from these Terms. 
                        </p>
                        <p> 27.    Termination of Use
                        If  you breach any provision of these Terms, then you may no longer use  this Service. We, in our sole discretion, shall determine whether these  Terms have been violated. You agree that we may, in our sole discretion,  terminate or suspend your account with or without notice.
                        Upon  termination or suspension of your use, regardless of the reasons  therefore, your right to use this Service immediately ceases, and you  acknowledge and agree that you shall immediately deactivate or unstill  the App and all related information and files on your device and/or bar  any further access to such files or this Service. We shall not be liable  to you or any third party for any claims or damages arising out of any  termination or suspension or any other actions taken by us in connection  with such termination or suspension.
                        </p>
                        <p> 28.    General
                        These Terms  constitute the entire agreement between us and you with respect to the  subject matter hereof, and supersede all communications, representations  or agreements, either oral or written, between us or our affiliates and  you with respect to this subject matter. Notwithstanding the foregoing,  if you and the Company agree to a written agreement and such agreement  expressly overrides these Terms, the provisions of such agreement will  prevail. If for any reason a court of competent jurisdiction finds any  provision of these Terms or portion thereof to be unenforceable, that  provision shall be enforced to the maximum extent permissible so as to  effect the intent of these Terms, and the remainder of these Terms shall  continue in full force and effect. 
                        No waiver of or consent to  depart from the requirements of any provision of these Terms will be  binding unless it is in writing and signed by the Company. You and the  Company are independent contractors, and no agency, partnership, joint  venture, employment or franchise relationship is intended or created by  these Terms.
                        The provisions of these Terms will enure to the benefit  of and be binding upon you and the Company and its respective successors  and assigns, and, if you are contracting as an individual, your heirs,  executors, administrators and personal representatives. You may not  assign these Terms or your rights and obligations under these Terms  without our express prior written consent, which may be withheld in our  sole discretion. We may assign these Terms and our respective rights and  obligations under these Terms without your consent. In these Terms the  word “including” or “includes” or any variation thereof means including,  without limitation and shall not be construed to limit any general  statement that it follows to the specific or similar items or matters  immediately following it.
                        </p>
                        <p> 29.    Notices
                        All notices to us shall  be in writing and shall be made via email. Notices to us must be sent to  the attention of our customer service representatives at  midnightnoiserbg@gmail.com. Notices to you may be sent, in our sole  discretion, electronically via notification in the App. In addition, we  may broadcast notices or messages through this Service to inform you of  changes to this Service or other matters of importance, and such  broadcasts shall constitute notice to you at the time of sending.
                        By  agreeing to these Terms you consent and agree to the Company sending you  account-based information and alerts regarding your account by  notification in the App, text and/or push notifications on your mobile  device on a periodic basis. In addition, or in the alternative, we may  broadcast notices or messages through this Service to inform you of  changes to this Service or other matters of importance, and such  broadcasts shall constitute notice to you at the time of sending.
                        </p>
                        <p> 30.    Apple Terms and Conditions
                        In  the event that you have downloaded a version of the Service for use on  the iOS platform, the additional provisions set out in this Section  apply to your use of such version of the Service. When we say “Apple” in  this Section we mean “Apple Inc.”.
                        The parties acknowledge that  these terms are concluded between the parties only and not with Apple,  and us, not Apple, are solely responsible for the Service and the  content thereof. In the event that these terms provide for usage rules  for the Service that are less restrictive than the Usage Rules set forth  for the Service in, or otherwise be in conflict with, the App Store  Terms of Service as of the effective date of these terms, such usage  rules for the Service will not be in force to the minimal extent of such  inconsistency.
                        The Service license is limited to a non-transferable  license to use the Service on any Apple-branded Products (as defined in  the Apple Usage Rules) that you own or control and as permitted by the  Usage Rules set forth in the App Store Terms of Service, except that  such Service may be accessed and used by other accounts associated with  the purchaser via Family Sharing (as defined in the Apple Usage Rules)  or volume purchasing.
                        The parties acknowledge that Apple has no  obligation whatsoever to furnish any maintenance and support services  with respect to the Service.
                        To the extent any warranty is imposed  upon the Service pursuant to any applicable law, in the event of any  failure of the Service to conform to any applicable warranty, you may  notify Apple, and Apple will refund the purchase price, if any, for the  Service to you; and, to the maximum extent permitted by applicable law,  Apple will have no other warranty obligation whatsoever with respect to  the Service, and any other claims, losses, liabilities, damages, costs  or expenses attributable to any failure to conform to any such warranty  will be our sole responsibility.
                        The parties acknowledge that Apple  is not responsible for addressing any claims of yours or any third party  relating to the Service or your possession and/or use of the Service,  including: (1) product liability claims; (2) any claim that the Service  fails to conform to any applicable legal or regulatory requirement; and  (3) claims arising under consumer protection, privacy or similar  legislation.
                        The parties acknowledge that, in the event of any third  party claims that the Service or your possession and use of the Service  infringes that third party’s intellectual property rights, you, not  Apple, will be solely responsible for the investigation, defense,  settlement and discharge of any such intellectual property infringement  claim.
                        You represent and warrant that (i) you are not located in a  country that is subject to a U.S. Government embargo, or that has been  designated by the U.S. Government as a “terrorist supporting” country;  and (ii) you are not listed on any U.S. Government list of prohibited or  restricted parties.
                        You must comply with applicable third party terms of agreement when using the Service.
                        The  parties acknowledge and agree that Apple, and Apple’s subsidiaries, are  third party beneficiaries of these terms in respect of the Service, and  that, upon your acceptance of these terms, Apple will have the right  (and will be deemed to have accepted the right) to enforce these terms  in respect of the Service against you as a third party beneficiary  thereof.
                        </p>
                        <p> 31.    Language
                        The parties have required that this agreement and all documents relating thereto be drawn up in English. Les parties ont demandé que cette convention ainsi que tous les documents  qui s’y attachent soient rédigés en langue anglaise.
                        </p>
                        </Tab>

                </Tabs>
                  
                </div>
              </div>
        </section>
                  
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Details